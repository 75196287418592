// This function is used to provide error messages to the user that are
// different than the error messages provided by the SDK.
export default function enhanceMessage(message = '', code?: number) {
  switch (true) {
    case code === 20101: // Invalid token error
      return message + '. Please make sure you are using the correct credentials.';
    case message === 'Permission denied by system':
      return 'Unable to share your screen. Please make sure that your operating system has the correct permissions enabled for screen sharing.';
    case code === 53105: // Too many participants
      return 'This is a popular meeting! Reach out to the host ASAP, this call has reached its maximum number of participants!';
    default:
      // frontend errors:
      switch (message) {
        case 'Unexpected end of JSON input':
          return "Something's not right, this is the wrong link! Double check your link and try again.";
        case 'Failed to fetch':
          return 'Sorry this issue is on our side. Please contact your support or try later.';
        case 'token must be a string': // This is not very precise. We should get the error from the Backend and do the if with it
          return "Your timing might be off, this meeting either hasn't started yet or is already over! Double check your meeting invite.";
        case 'Permission denied': // This is also not very precise
          return 'You need to give us access to your camera in order to use the video feature. Try checking your browser settings and make sure that all other websites using your camera are closed.';
        default:
          return message;
      }
  }
}
