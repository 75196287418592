import { Store, get, set } from 'idb-keyval';

const appStore = new Store('pwa-cache', 'data');
const usernameStore = 'displayName';

export function getUsername() {
  return get(usernameStore, appStore).then(val => val);
}

export function setUsername(username: string) {
  return set(usernameStore, username, appStore);
}
