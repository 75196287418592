import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import Menu from './Menu/Menu';

import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden, Tooltip } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'var(--sib-color_primary-default)',
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'relative',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'absolute',
      zIndex: 10,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: '#eb4d5c',
        border: `2px solid #eb4d5c`,
        margin: '0 2em',
        '&:hover': {
          color: '#d34552',
          border: `2px solid #d34552`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    controlButton: {
      '& span:first-child': {
        height: '100%',
        flexDirection: 'column',
        color: '#fff',
      },
      '& span:first-child span': {
        margin: '0',
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const { t } = useTranslation();
  const isReconnecting = roomState === 'reconnecting';

  return (
    <footer className={classes.container}>
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="subtitle1">{t('ROOM.SCREEN_SHARE_INFO')}</Typography>
          <Tooltip
            title={t('ROOM.STOP_SCREEN_SHARE_TEXT_TOOLTIP')}
            placement="top"
            PopperProps={{ disablePortal: true }}
          >
            <Button onClick={() => toggleScreenShare()}>{t('ROOM.STOP_SCREEN_SHARE_TEXT')}</Button>
          </Tooltip>
        </Grid>
      )}
      <Grid container justifyContent="space-around" alignItems="center">
        <Hidden smDown>
          <Grid style={{ flex: 1 }} container alignItems="center">
            <Menu buttonClassName={classes.controlButton} />
          </Grid>
        </Hidden>
        <Grid item>
          <Grid container justifyContent="center">
            <ToggleAudioButton iconFill="#fff" controls className={classes.controlButton} disabled={isReconnecting} />
            <ToggleVideoButton iconFill="#fff" controls className={classes.controlButton} disabled={isReconnecting} />
            <Hidden smDown>
              {!isSharingScreen && (
                <ToggleScreenShareButton iconFill="#fff" className={classes.controlButton} disabled={isReconnecting} />
              )}
            </Hidden>
            <FlipCameraButton iconFill="#fff" className={classes.controlButton} />
            <ToggleChatButton iconFill="#fff" className={classes.controlButton} />
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid style={{ flex: 1 }}>
            <Grid container justifyContent="flex-end">
              <EndCallButton />
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </footer>
  );
}
