import { Button, Badge } from '@material-ui/core';
import { Tooltip } from '@dtsl/react';
import ChatIcon from '../../../icons/ChatIcon';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import withStyles from '@material-ui/core/styles/withStyles';
import { useTranslation } from 'react-i18next';

const UnreadMessagesCountBadge = withStyles({
  badge: {
    background: '#DDE5E9',
    color: 'var(--sib-color_content-primary)',
  },
})(Badge);

export default function ToggleChatButton(props: { className?: string; iconFill?: string }) {
  const { isChatWindowOpen, setIsChatWindowOpen, unreadMessagesCount } = useChatContext();
  const { setIsBackgroundSelectionOpen } = useVideoContext();
  const { t } = useTranslation();

  const toggleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen);
    setIsBackgroundSelectionOpen(false);
  };

  return (
    <Tooltip text={t('ROOM.CHAT_TOOLTIP')} placement="top" trigger={['hover']}>
      <Button
        className={props.className}
        onClick={toggleChatWindow}
        startIcon={
          <UnreadMessagesCountBadge overlap="rectangular" badgeContent={unreadMessagesCount}>
            <ChatIcon fill={props.iconFill} />
          </UnreadMessagesCountBadge>
        }
      >
        {t('ROOM.CHAT')}
      </Button>
    </Tooltip>
  );
}
