import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../icons/CloseIcon';
import BackgroundIcon from '../../../icons/BackgroundIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: `${theme.footerHeight}px`,
      boxShadow: 'inset 0 -1px 0 rgb(0 0 0 / 12%)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 1em',
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
      },
    },
    title: {
      display: 'inline-flex',
      alignItems: 'center',
      '& svg': {
        marginRight: '6px',
      },
    },
    closeBackgroundSelection: {
      cursor: 'pointer',
      display: 'flex',
    },
  })
);

interface BackgroundSelectionHeaderProps {
  onClose: () => void;
}

export default function BackgroundSelectionHeader({ onClose }: BackgroundSelectionHeaderProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div></div>
      <div className={clsx(classes.title, 'sib-typo_heading-sm')}>
        <BackgroundIcon />
        {t('ROOM.BACKGROUNDS')}
      </div>
      <div className={classes.closeBackgroundSelection} onClick={onClose}>
        <CloseIcon />
      </div>
    </div>
  );
}
