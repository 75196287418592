import EndContainer from '../EndContainer/EndContainer';
import { List, ListItemIcon, ListItem, ListItemText } from '@material-ui/core';
import { Button, Link } from '@dtsl/react';

import { makeStyles } from '@material-ui/core/styles';
import { useAppState } from '../../state';
import { useTranslation } from 'react-i18next';
import StarIcon from '@material-ui/icons/Star';

const useStyles = makeStyles({
  list: {
    display: 'table',
    margin: '0 auto',
  },
  listText: {
    fontSize: '1rem',
    fontWeight: 500,
  },
  listIconItem: {
    minWidth: '40px',
  },
  listIcon: {
    height: '32px',
    width: '32px',
    color: '#F2CD00',
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& form': {
      margin: '1em auto',
    },
    '& a': {
      fontSize: '1rem',
      textAlign: 'center',
    },
  },
  ctaButton: {
    display: 'flex',
    justifyContent: 'center',
    margin: '1.5em auto 0',
  },
});

export default function EndScreens() {
  const classes = useStyles();
  const { user } = useAppState();
  const { t } = useTranslation();

  return (
    <EndContainer>
      <p className="sib-typo_text-body">
        <Link url="" variant="default" labelText={t('END.RETURN_TO_CALL')} />
      </p>
      {!user?.isExpert ? (
        <>
          <p className="sib-typo_heading-sm">
            {t('END.GUEST.TITLE_1')}
            <br />
            {t('END.GUEST.TITLE_2')}
          </p>
          <p className="sib-typo_text-body">
            {t('END.GUEST.SUBTITLE')}
            <br />
            {t('END.GUEST.SUBTITLE_2')}
          </p>
          <List
            classes={{
              root: classes.list,
            }}
          >
            <ListItem>
              <ListItemIcon
                classes={{
                  root: classes.listIconItem,
                }}
              >
                <StarIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.listText,
                }}
                primary={t('END.GUEST.FEATURE_1')}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon
                classes={{
                  root: classes.listIconItem,
                }}
              >
                <StarIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.listText,
                }}
                primary={t('END.GUEST.FEATURE_2')}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon
                classes={{
                  root: classes.listIconItem,
                }}
              >
                <StarIcon className={classes.listIcon} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  className: classes.listText,
                }}
                primary={t('END.GUEST.FEATURE_3')}
              />
            </ListItem>
          </List>
          <div className={classes.ctaContainer}>
            <form action="https://www.sendinblue.com/features/meetings/">
              <input type="hidden" name="utm_campaign" value="referral" />
              <input type="hidden" name="utm_medium" value="video_call" />
              <input type="hidden" name="utm_source" value="sign_up_button" />
              <input type="hidden" name="utm_content" value="en" />
              <Button variant="primary" label={t('END.GUEST.BUTTON')} type="submit"></Button>
            </form>
            <Link
              url="https://www.sendinblue.com/features/meetings/?utm_medium=video_call&utm_source=learn_more_button&utm_campaign=referral&utm_content=en"
              variant="external"
              labelText={t('END.GUEST.BUTTON_SECONDARY')}
            />
          </div>
        </>
      ) : (
        <>
          <div className="sib-typo_heading-sm">{t('END.TITLE')}</div>
          <div className={classes.ctaButton}>
            <Button
              variant="primary"
              label={t('END.BUTTON')}
              type="button"
              onClick={() => window.location.replace('https://app.sendinblue.com/meetings/appointments')}
            ></Button>
          </div>
        </>
      )}
    </EndContainer>
  );
}
