import { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { Button } from '@dtsl/react';

const useStyles = makeStyles({
  copyContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    paddingBottom: '2em',
  },
  inputContainer: {
    width: 'calc(70% - 15px)',
  },
  input: {
    fontFamily: 'inherit',
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.7)',
    color: '#000000',
    textAlign: 'center',
    border: '1px solid #AAAAAA',
    borderRadius: '5px',
    fontSize: '1rem',
    outline: 'none',
  },
  buttonContaner: {
    width: 'calc(30%)',
  },
});

export default function CopyLink() {
  const classes = useStyles();
  const roomLink = window.location.hostname + window.location.pathname;
  const { t } = useTranslation();
  const [copyButtonState, setcopyButtonState] = useState<string>(t('PREVIEW.DEVICES.COPY_LINK'));
  const copyTextRef = useRef<HTMLInputElement>(null);

  const copyToClipboard = () => {
    if (null !== copyTextRef.current) {
      copyTextRef.current.select();
      document.execCommand('copy');
      setcopyButtonState(t('PREVIEW.DEVICES.COPIED_LINK'));
      setTimeout(() => {
        setcopyButtonState(t('PREVIEW.DEVICES.COPY_LINK'));
      }, 2000);
    }
  };

  return (
    <div className={classes.copyContainer}>
      <div className={classes.inputContainer}>
        <input type="url" ref={copyTextRef} className={classes.input} readOnly defaultValue={roomLink} />
      </div>
      {document.queryCommandSupported('copy') && (
        <div className={classes.buttonContaner}>
          <Button onClick={copyToClipboard} variant="secondary" label={copyButtonState} fullWidth>
            {copyButtonState}
          </Button>
        </div>
      )}
    </div>
  );
}
