import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';
import { Tooltip } from '@dtsl/react';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: 'var(--sib-color_negative-default)',
      color: 'white',
      '&:hover': {
        background: 'var(--sib-color_negative-hover)',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { t } = useTranslation();

  return (
    <Tooltip text={t('ROOM.LEAVE_TOOLTIP')} placement="top" trigger={['hover']}>
      <Button onClick={() => room!.disconnect()} className={clsx(classes.button, props.className)} data-cy-disconnect>
        {t('ROOM.LEAVE')}
      </Button>
    </Tooltip>
  );
}
