import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, styled, Theme } from '@material-ui/core/styles';

import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import ChatWindow from './components/ChatWindow/ChatWindow';
import BackgroundSelectionDialog from './components/BackgroundSelectionDialog/BackgroundSelectionDialog';
import EndScreens from './components/EndScreens/EndScreens';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import useChatContext from './hooks/useChatContext/useChatContext';
import useVideoContext from './hooks/useVideoContext/useVideoContext';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      position: 'relative',
      height: 'inherit',
      display: 'grid',
      gridTemplateRows: '100%',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '100%',
        gridTemplateRows: '100%',
      },
    },
    rightDrawerOpen: { gridTemplateColumns: `1fr ${theme.rightDrawerWidth}px` },
  };
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

export default function App() {
  const roomState = useRoomState();
  const [tookPlace, setTookPlace] = useState<Boolean>(false);

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  const classes = useStyles();
  const { isChatWindowOpen } = useChatContext();
  const { isBackgroundSelectionOpen } = useVideoContext();

  useEffect(() => {
    if (roomState === 'connected') setTookPlace(true);
  }, [roomState]);

  return (
    <Container style={{ height }}>
      {roomState === 'disconnected' ? (
        tookPlace ? (
          <EndScreens />
        ) : (
          <PreJoinScreens />
        )
      ) : (
        <div
          className={clsx(classes.container, {
            [classes.rightDrawerOpen]: isChatWindowOpen || isBackgroundSelectionOpen,
          })}
        >
          <Main>
            <ReconnectingNotification />
            <MobileTopMenuBar />
            <Room />
            <MenuBar />
          </Main>
          <ChatWindow />
          <BackgroundSelectionDialog />
        </div>
      )}
    </Container>
  );
}
