import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../assets/locales/en.json';
import de from '../assets/locales/de.json';
import es from '../assets/locales/es.json';
import fr from '../assets/locales/fr.json';
import it from '../assets/locales/it.json';
import pt from '../assets/locales/pt.json';

// translations
const resources = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  it: {
    translation: it,
  },
  pt: {
    translation: pt,
  },
} as const;

i18n
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
  });
export default i18n;
