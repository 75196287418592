import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY, SELECTED_VIDEO_LABEL_KEY } from '../../../constants';
import { Dropdown } from '@dtsl/react';
import { LocalVideoTrack } from 'twilio-video';
import { makeStyles } from '@material-ui/core/styles';
import VideoTrack from '../../VideoTrack/VideoTrack';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles({
  preview: {
    width: '300px',
    maxHeight: '200px',
    margin: '0.5em auto',
    '& video': {
      maxHeight: '200px',
    },
  },
});

export default function VideoInputList() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDevice, setStoredLocalVideoDevice] = useState([
    {
      label: window.localStorage.getItem(SELECTED_VIDEO_LABEL_KEY),
      value: window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY),
    },
  ]);

  const localVideoInputDevice =
    [{ value: mediaStreamTrack?.getSettings().deviceId, label: mediaStreamTrack?.label }] || storedLocalVideoDevice;

  function replaceTrack(device: { label: string; value: string }) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDevice([device]);
    window.localStorage.setItem(SELECTED_VIDEO_LABEL_KEY, device.label);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, device.value);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: device.value },
    });
  }

  const options = videoInputDevices.map(device => ({
    value: device.deviceId,
    label: device.label,
  }));

  return (
    <div>
      {localVideoTrack && (
        <div className={classes.preview}>
          <VideoTrack isLocal track={localVideoTrack} />
        </div>
      )}
      {videoInputDevices.length > 1 ? (
        <Dropdown
          label={t('ROOM.CAM_SELECT')}
          options={options}
          onSelect={(option: { label: string; value: string }) => replaceTrack(option)}
          value={localVideoInputDevice || []}
        />
      ) : (
        <>
          <p className="sib-typo_text-body--bold">{t('ROOM.CAM_SELECT')}</p>
          <p className="sib-typo_text-body">{localVideoTrack?.mediaStreamTrack.label || t('ROOM.CAM_NO_SELECT')}</p>
        </>
      )}
    </div>
  );
}
