import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomScreen from './RoomScreen/RoomScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export enum Steps {
  roomStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLExpertSlug, URLRoom } = useParams<{ URLExpertSlug: string; URLRoom: string }>();
  const [step, setStep] = useState(Steps.roomStep);

  const [name, setName] = useState<string>('');
  const [slug, setSlug] = useState<string>('');
  const [room, setRoom] = useState<string>('');

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (URLExpertSlug) {
      setSlug(URLExpertSlug);
    }
    if (URLRoom) {
      setRoom(URLRoom);
      if (user?.displayName) {
        setName(user?.displayName);
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLExpertSlug, URLRoom]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStep(Steps.deviceSelectionStep);
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomStep && (
        <RoomScreen name={name} slug={slug} room={room} setName={setName} handleSubmit={handleSubmit} />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} slug={slug} room={room} setStep={setStep} />
      )}
    </IntroContainer>
  );
}
