import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator';
import { LocalAudioTrack } from 'twilio-video';
import { Grid } from '@material-ui/core';
import { Dropdown } from '@dtsl/react';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useTranslation } from 'react-i18next';

export default function AudioInputList() {
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDevice = [{ value: mediaStreamTrack?.getSettings().deviceId, label: mediaStreamTrack?.label }];
  const { t } = useTranslation();

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  const options = audioInputDevices.map(device => ({
    value: device.deviceId,
    label: device.label,
  }));

  return (
    <div>
      <Grid container alignItems="flex-end" justifyContent="space-between">
        <div className="inputSelect">
          {audioInputDevices.length > 1 ? (
            <Dropdown
              label={t('ROOM.MIC_SELECT')}
              options={options}
              onSelect={(option: { value: string }) => replaceTrack(option.value as string)}
              value={localAudioInputDevice || []}
            />
          ) : (
            <p className="sib-typo_text-body">{localAudioTrack?.mediaStreamTrack.label || t('ROOM.MIC_NO_SELECT')}</p>
          )}
        </div>
        <AudioLevelIndicator audioTrack={localAudioTrack} color="black" />
      </Grid>
    </div>
  );
}
