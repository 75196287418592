import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function useLanguage() {
  const [lang, setLang] = useState<string>('en');
  const location = useLocation();
  const { i18n } = useTranslation();

  const changeLanguage = useCallback(
    (language: string) => {
      setLang(language);
      i18n.changeLanguage(language);
    },
    [i18n]
  );

  useEffect(() => {
    const locale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
    if (locale) {
      changeLanguage(locale.substring(0, 2));
    } else {
      const headers = new window.Headers();
      headers.set('application_hash', process.env.REACT_APP_APPLICATION_HASH as string);
      const params = new window.URLSearchParams({
        slug: location.pathname.split('/')[2],
      });
      const endpoint = ((process.env.REACT_APP_API_URL as string) +
        process.env.REACT_APP_EXPERT_LANGUAGE_ENDPOINT) as string;
      fetch(`${endpoint}?${params}`, { headers })
        .then(res => res.json())
        .then((res: { lang: string }) => changeLanguage(res.lang))
        .catch(() => changeLanguage('en'));
    }
  }, [location, changeLanguage]);

  return { lang };
}
