import clsx from 'clsx';
import { makeStyles, Grid, Theme, Hidden } from '@material-ui/core';
import { Button } from '@dtsl/react';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import CopyLink from './CopyLink/CopyLink';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: '1em',
    textAlign: 'center',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    margin: '1em 0',
    '& span:first-child span': {
      margin: '0',
    },
  },
  localPreviewContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: '0 2.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2em 0 0',
    width: '100%',
    '& button': {
      margin: '0 .5em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1em 0 0',
      flexDirection: 'column-reverse',
      '& button': {
        margin: '0.5em 0',
      },
    },
  },
  mobileButtonBar: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 1em',
    },
  },
  mobileButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  slug: string;
  room: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, slug, room, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching, user } = useAppState();
  const { connect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const { t } = useTranslation();

  const handleJoin = () => {
    getToken(name, slug, room).then(token => connect(token));
  };

  return (
    <>
      <div className={clsx(classes.header, 'sib-typo_heading-sm')}>
        {t('PREVIEW.DEVICES.TITLE_1')}
        {name}
        {t('PREVIEW.DEVICES.TITLE_2')}
      </div>
      <Hidden smDown>
        <CopyLink />
      </Hidden>

      <Grid container justifyContent="center">
        <Grid item md={2} sm={12} xs={12}></Grid>
        <Grid item md={8} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.mobileButtonBar}>
            <Hidden mdUp>
              <ToggleAudioButton className={classes.mobileButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.mobileButton} disabled={disableButtons} />
              <SettingsMenu className={classes.mobileButton} />
            </Hidden>
          </div>
        </Grid>
        <Grid item md={1} sm={12} xs={12}>
          <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
            <Hidden smDown>
              <ToggleAudioButton className={classes.deviceButton} disabled={disableButtons} />
              <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} />
              <SettingsMenu className={classes.deviceButton} />
            </Hidden>
          </Grid>
        </Grid>
        <Grid item md={1} sm={12} xs={12}></Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container>
            <div className={classes.joinButtons}>
              {!user?.isExpert && (
                <Button
                  variant="secondary"
                  label={t('PREVIEW.DEVICES.BACK')}
                  onClick={() => setStep(Steps.roomStep)}
                ></Button>
              )}
              <Button
                variant="primary"
                label={t('PREVIEW.DEVICES.JOIN')}
                data-cy-join-now
                onClick={handleJoin}
                disabled={disableButtons}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
