import { ChangeEvent, FormEvent } from 'react';
import clsx from 'clsx';
// eslint-disable-next-line
import { makeStyles, Theme, useMediaQuery } from '@material-ui/core';
import { Inputbox, Button, Link } from '@dtsl/react';
import { useAppState } from '../../../state';
import { setUsername } from '../../../utils/indexedDB/indexedDB';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    marginBottom: '1em',
    textAlign: 'center',
  },
  gutterBottom: {
    marginBottom: '2em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingBottom: '2em',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      '& button': {
        marginTop: '1em',
      },
    },
  },
  textFieldContainer: {
    width: 'calc(70% - 10px)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  continueButton: {
    width: 'calc(30%)',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  footer: {
    textAlign: 'center',
  },
}));

interface RoomScreenProps {
  name: string;
  slug: string;
  room: string;
  setName: (name: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomScreen({ name, slug, room, setName, handleSubmit }: RoomScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setName(event.target.value);
  };

  return (
    <>
      <div className={clsx(classes.header, 'sib-typo_heading-sm')}>{t('PREVIEW.NAME.TITLE')}</div>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {!user?.isExpert && (
            <div className={classes.textFieldContainer}>
              <Inputbox
                identifier="input-user-name"
                placeholder={t('PREVIEW.NAME.INPUT.LABEL')}
                value={name}
                onChange={handleNameChange}
              />
            </div>
          )}
          <div className={classes.continueButton}>
            <Button
              variant="primary"
              label={t('PREVIEW.NAME.INPUT.SUBMIT')}
              type="submit"
              disabled={!name || !room || !slug}
              fullWidth={isMobile}
            ></Button>
          </div>
        </div>
      </form>
      <>
        {/* ToDo: Add className={classes.gutterBottom} */}
        <p className={clsx(classes.header, 'sib-typo_text-body')}>
          {t('PREVIEW.NAME.CONSENT_1')}
          <Link
            url="https://www.sendinblue.com/legal/termsofuse/"
            variant="external"
            labelText={t('PREVIEW.NAME.CONSENT_2')}
          />
          {t('PREVIEW.NAME.CONSENT_3')}
        </p>
        {/* ToDo: temporarily hidden until user authentication is working */}
        {/* <p className={clsx(classes.header, "sib-typo_text-body")}>
          {t('PREVIEW.NAME.LOGIN_1')}
          <Link
            url="https://app.sendinblue.com/account/login"
            variant="default"
            labelText={t('PREVIEW.NAME.LOGIN_2')}
          />
        </p> */}
      </>
    </>
  );
}
