import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import MUISnackbar from '@material-ui/core/Snackbar';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles({
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '4px 14px 4px 6px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: '50px',
    color: 'white',
  },
  icon: {
    alignSelf: 'center',
    margin: '0 8px',
    color: '#eb4d5c',
  },
});

export default function IsRecordingSnackbar(props: { isRecording: boolean; className?: string }) {
  const classes = useStyles();

  return (
    <MUISnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={props.isRecording}
      className={props.className}
    >
      <div className={classes.contentContainer}>
        <FiberManualRecordIcon fontSize="small" className={classes.icon} />
        <Typography variant="subtitle1" component="span">
          This meeting is being recorded
        </Typography>
      </div>
    </MUISnackbar>
  );
}
