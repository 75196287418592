import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from '@dtsl/react';
import linkify from 'linkify-it';

const useStyles = makeStyles({
  message: {
    display: 'flex',
    justifyContent: 'flex-start',
    fontSize: '14px',
    lineHeight: '20px',
    wordBreak: 'break-word',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
  },
  messageSelf: {
    justifyContent: 'flex-end',
  },
  messageContent: {
    backgroundColor: 'var(--sib-color_surface-grey-background)',
    padding: '6px 12px',
    display: 'inline-block',
    maxWidth: '100%',
    borderRadius: '5px',
  },
  messageContentSelf: {
    backgroundColor: 'var(--sib-color_primary-background)',
  },
});

interface MessagesProps {
  messages: string[];
  ownedByCurrentUser: boolean;
}

function addLinks(text: string) {
  const matches = linkify().match(text);
  if (!matches) return text;

  const results = [];
  let lastIndex = 0;

  matches.forEach((match, i) => {
    results.push(text.slice(lastIndex, match.index));
    results.push(<Link url={match.url} variant="external" labelText={match.text} key={i} />);
    lastIndex = match.lastIndex;
  });

  results.push(text.slice(lastIndex, text.length));

  return results;
}

export default function Messages({ messages, ownedByCurrentUser }: MessagesProps) {
  const classes = useStyles();

  return (
    <>
      {messages.map((message, idx) => (
        <div
          key={idx}
          className={clsx(classes.message, {
            [classes.messageSelf]: ownedByCurrentUser,
          })}
        >
          <div
            className={clsx(classes.messageContent, {
              [classes.messageContentSelf]: ownedByCurrentUser,
            })}
          >
            <span>{addLinks(message)}</span>
          </div>
        </div>
      ))}
    </>
  );
}
