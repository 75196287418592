import { useCallback, useRef } from 'react';

import { Tooltip } from '@dtsl/react';
import { Button } from '@material-ui/core';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { useTranslation } from 'react-i18next';

export default function ToggleVideoButton(props: {
  disabled?: boolean;
  className?: string;
  controls?: boolean;
  iconFill?: string;
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();
  const { t } = useTranslation();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Tooltip
      text={isVideoEnabled ? t('ROOM.CAM_ON_TOOLTIP') : t('ROOM.CAM_OFF_TOOLTIP')}
      placement="top"
      trigger={['hover']}
    >
      <Button
        className={props.className}
        onClick={toggleVideo}
        disabled={!hasVideoInputDevices || props.disabled}
        startIcon={isVideoEnabled ? <VideoOnIcon fill={props.iconFill} /> : <VideoOffIcon fill={props.iconFill} />}
      >
        {!hasVideoInputDevices ? t('ROOM.NO_CAM') : props.controls ? t('ROOM.CAM') : ''}
      </Button>
    </Tooltip>
  );
}
