import { Grid } from '@material-ui/core';
import { Dropdown } from '@dtsl/react';
import { useAppState } from '../../../state';
import { useTranslation } from 'react-i18next';

const MAX_PARTICIPANT_OPTIONS = [6, 12, 24];

export default function MaxGalleryViewParticipants() {
  const { maxGalleryViewParticipants, setMaxGalleryViewParticipants } = useAppState();
  const { t } = useTranslation();

  const options = MAX_PARTICIPANT_OPTIONS.map(option => ({
    value: option,
    label: option.toString(),
  }));

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between">
        <div className="inputSelect">
          <Dropdown
            label={t('ROOM.GALLERY_VIEW_PARTICIPANTS')}
            options={options}
            onSelect={(option: { value: number }) => setMaxGalleryViewParticipants(option.value)}
            value={[{ value: maxGalleryViewParticipants, label: maxGalleryViewParticipants.toString() }] || []}
          />
        </div>
      </Grid>
    </div>
  );
}
