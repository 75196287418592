import { createRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import MessageGroups from './MessageGroups/MessageGroups';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    position: 'relative',
    height: '100%',
    overflowY: 'auto',
  },
  messages: {
    padding: '8px 0',
    width: '100%',
    minHeight: 'calc(100% - 1px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  scroller: {
    width: '100%',
    height: '1px',
  },
});

export default function ChatWindowBody() {
  const classes = useStyles();
  const { messages } = useChatContext();
  const scroller = createRef<HTMLDivElement>();

  useEffect(() => {
    scroller.current && scroller.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages, scroller]);

  return (
    <div className={classes.container}>
      <div className={classes.messages}>
        <MessageGroups messageGroups={messages} />
      </div>
      <div className={classes.scroller} ref={scroller}></div>
    </div>
  );
}
