export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'Sendinblue-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'Sendinblue-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'Sendinblue-selectedVideoInput';
export const SELECTED_VIDEO_LABEL_KEY = 'Sendinblue-selectedVideoLabel';

// This is used to store the current background settings in localStorage
export const SELECTED_BACKGROUND_SETTINGS_KEY = 'Sendinblue-selectedBackgroundSettings';

export const GALLERY_VIEW_ASPECT_RATIO = 9 / 16; // 16:9
export const GALLERY_VIEW_MARGIN = 3;
