import { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { getUsername } from '../../utils/indexedDB/indexedDB';

export default function useIndexedDB() {
  const [isScheduled, setIsScheduled] = useState(false);
  const [user, setUser] = useState<null | {
    id: number | undefined;
    displayName: string;
    isExpert: boolean;
    photoURL: string | undefined;
  }>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const location = useLocation();

  useEffect(() => {
    getUsername().then(username => {
      setUser({ id: undefined, displayName: username as string, isExpert: false, photoURL: undefined });
      setIsAuthReady(true);
    });
    setIsScheduled(location.pathname.split('/')[1] === 'video');
  }, [location]);

  const getToken = useCallback(
    async (identity: string, slug: string, room: string) => {
      const headers = new window.Headers();
      headers.set('application_hash', process.env.REACT_APP_APPLICATION_HASH as string);
      const params = new window.URLSearchParams({ identity, slug });
      if (isScheduled) {
        isNaN(Number(room)) ? params.append('appointment_hash', room) : params.append('appointment_id', room);
      } else {
        params.append('roomName', room);
      }
      const endpointPath = isScheduled
        ? process.env.REACT_APP_TOKEN_SCHEDULED_ENDPOINT
        : process.env.REACT_APP_TOKEN_ENDPOINT;
      const endpoint = ((process.env.REACT_APP_API_URL as string) + endpointPath) as string;

      return fetch(`${endpoint}?${params}`, { headers }).then(res => res.json());
    },
    [isScheduled]
  );

  const getTestTokens = useCallback(async (publisher: string, subscriber: string, roomName: string) => {
    const headers = new window.Headers();
    headers.set('application_hash', process.env.REACT_APP_APPLICATION_HASH as string);
    const endpoint = ((process.env.REACT_APP_API_URL as string) + process.env.REACT_APP_TEST_TOKEN_ENDPOINT) as string;
    const params = new window.URLSearchParams({ publisher, subscriber, roomName });

    return fetch(`${endpoint}?${params}`, { headers }).then(res => res.json());
  }, []);

  return { isScheduled, user, isAuthReady, getToken, getTestTokens };
}
