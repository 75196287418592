import { Dropdown } from '@dtsl/react';
import { useAppState } from '../../../state';
import { useTranslation } from 'react-i18next';
import useDevices from '../../../hooks/useDevices/useDevices';

export default function AudioOutputList() {
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const { t } = useTranslation();
  const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)?.label;

  const options = audioOutputDevices.map(device => ({
    value: device.deviceId,
    label: device.label,
  }));
  return (
    <div className="inputSelect">
      {audioOutputDevices.length > 1 ? (
        <Dropdown
          label={t('ROOM.SPEAKER_SELECT')}
          options={options}
          onSelect={(option: { value: string }) => setActiveSinkId(option.value as string)}
          value={[{ value: activeSinkId, label: activeOutputLabel }] || []}
        />
      ) : (
        <>
          <p className="sib-typo_text-body--bold">{t('ROOM.SPEAKER_SELECT')}</p>
          <p className="sib-typo_text-body">{activeOutputLabel || t('ROOM.SPEAKER_NO_SELECT')}</p>
        </>
      )}
    </div>
  );
}
